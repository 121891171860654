<template>
  <div align="center">
    <v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
    <h1 :style="styleTitle">Bienvenue</h1>
    <div v-if="rejectStatus == 'rejected_signature'" align="left">
      <br />
      <div align="center ">
        <strong class="red--text">Votre signature a été refusée</strong>
      </div>
      <div align="center"><v-icon>mdi-draw</v-icon></div>
      <p>
        <span>
          Vous êtes invité à refaire gratuitement votre signature, la signature
          précédente n'étant correcte.
        </span>
      </p>
      <v-divider></v-divider>
      <div align="center"><v-icon color="red">mdi-alert-outline</v-icon></div>
      <span align="center">
        Tant que la signature n'est pas prise correctement, la commande ne
        pourra pas être validée par nos services de contrôle.
      </span>
    </div>
    <br />
    <v-btn
      :color="buttonValidColor"
      :style="'color:' + buttonTextValidColor"
      class="ml-auto"
      :disabled="canvasEmpty"
      @click="validate"
      :loading="uploading"
      >Suivant</v-btn
    >
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'WelcomeRedoSignature',
  data() {
    return {
      rejectStatus: null,
      photoRejectReasons: null,

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),

      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    }
  },
  methods: {
    validate() {
      this.$router.push({ name: 'Signature' })
    },
  },
  mounted() {
    if (
      localStorageService.getValue('shortliveApiKey') === undefined ||
      localStorageService.getValue('photoRejectReasons') === undefined
    ) {
      this.$router.push({ name: 'NotAuthorized' })
    } else {
      this.rejectStatus = localStorageService.getValue('rejectStatus')
      this.photoRejectReasons =
        localStorageService.getValue('photoRejectReasons')
    }
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
