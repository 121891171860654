<template>
  <v-container
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'stretch',
      overflowY: 'hidden',
      padding: '0px',
      backgroundSize: '57% 100%',
      backgroundPosition: 'right center',
    }"
    class="background-container"
  >
    <v-sheet
      v-if="showForm"
      class="d-flex flex-row align-center justify-center"
      :style="{
        borderRadius: '0px 25px 25px 0px',
        width: '45%',
        height: '100%',
      }"
    >
      <v-sheet>
        <v-img :src="icon" :width="iconWidth" class="mx-auto mb-10"></v-img>
        <div>
          <h1
            class="text-center mb-2"
            :style="{
              styleTitle,
              fontFamily: 'Georama',
              fontSize: 24,
              fontWeight: 700,
              marginTop: '15px',
            }"
          >
            Demande d’autorisation
          </h1>
        </div>
        <br />
        <v-sheet class="d-flex flex-column justify-center">
          <v-text-field
            density="dense"
            v-model="agenceName"
            placeholder="Nom Auto-ecole"
            prepend-inner-icon="mdi-car-outline"
            style="width: 400px; height: 52px"
            solo
            outlined
            :style="{ 'margin-top': '20px', borderRadius: '6px' }"
          ></v-text-field>
          <v-text-field
            density="dense"
            v-model="email"
            placeholder="Email de l'agence"
            prepend-inner-icon="mdi-email-outline"
            style="width: 400px; height: 52px"
            solo
            outlined
            :style="{ 'margin-top': '20px', borderRadius: '6px' }"
          ></v-text-field>
          <v-text-field
            density="dense"
            v-model="confEmail"
            placeholder="Confirmer Email"
            prepend-inner-icon="mdi-email-outline"
            style="width: 400px; height: 52px"
            solo
            outlined
            :style="{ 'margin-top': '20px', borderRadius: '6px' }"
          ></v-text-field>
          <v-text-field
            density="dense"
            v-model="externalId"
            placeholder="Identifiant interne"
            prepend-inner-icon="mdi-account-outline"
            style="width: 400px; height: 62px"
            solo
            outlined
            :style="{
              'margin-top': '20px',
              borderColor: '#47D881',
              borderRadius: '6px',
            }"
          ></v-text-field>
          <v-btn
            @click="submit"
            :color="color1"
            :style="{
              color: buttonTextValidColor,
              borderRadius: '30px',
              'text-transform': 'none',
              padding: '25px 70px',
              'font-family': 'Georama',
              'font-size': '24px',
              'font-weight': '600',
              'line-height': '20px',
              'letter-spacing': '0em',
              'text-align': 'center',
              marginTop: '20px',
            }"
            >Envoyer</v-btn
          >
        </v-sheet>
        <div
          style="
            position: absolute;
            bottom: 10px;
            left: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          "
          class="pl-3"
        >
          <p
            :style="{
              textAlign: 'center',
              margin: '0',
              color: '#000000',
              fontWeight: 700,
            }"
          >
            Made by Smartphone
          </p>
          <v-img
            :src="icon"
            :width="iconWidth"
            :style="{
              flex: '0 0 auto',
              maxWidth: '165.37px',
              maxHeight: '41.07px',
              margin: '5px 0 0 10px',
              borderRadius: '10px',
            }"
            class="mx-auto mb-1"
          ></v-img>
        </div>
      </v-sheet>
    </v-sheet>
    <v-sheet
      v-else
      class="d-flex flex-column align-center justify-center"
      :style="{
        borderRadius: '0px 25px 25px 0px',
        width: '45%',
        height: '100%',
      }"
    >
      <!-- <v-btn
          class="text-none"
          :style="{
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '400',
            'line-height': '20px',
            'letter-spacing': '0em',
            position: absolute,
            bottom: '-400px',
            left: '300px',
            display: flex,
            borderRadius: '50px',
            width: '60px',
            height: '60px',
            backgroundColor: '#2D4490',
          }"
          title="Télécharger"
        >
          <v-icon center size="36" color="#FFFFFF">mdi-download</v-icon>
        </v-btn> -->
      <!-- <v-btn
          class="text-none"
          :style="{
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '400',
            'line-height': '20px',
            'letter-spacing': '0em',
            position: absolute,
            bottom: '-430px',
            left: '300px',
            display: flex,
            borderRadius: '50px',
            width: '60px',
            height: '60px',
            backgroundColor: '#FFFFFF',
          }"
          title="Imprimer"
          @click="printCard"
        >
          <v-icon center size="36">mdi-printer</v-icon>
        </v-btn> -->
      <!-- <v-btn
          class="text-none"
          :style="{
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '400',
            'line-height': '20px',
            'letter-spacing': '0em',
            position: absolute,
            bottom: '-470px',
            left: '300px',
            display: flex,
            borderRadius: '50px',
            width: '60px',
            height: '60px',
            backgroundColor: '#FFFFFF',
          }"
          title="Partager"
        >
          <v-icon center size="36">mdi-share-variant-outline</v-icon>
        </v-btn> -->
      <v-btn
        class="text-none"
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          position: absolute,
          bottom: '-470px',
          left: '300px',
          display: flex,
          borderRadius: '50px',
          width: '60px',
          height: '60px',
          backgroundColor: '#FFFFFF',
        }"
        title="Home"
        @click="goBack"
      >
        <v-icon center size="36">mdi-home</v-icon>
      </v-btn>
      <v-card
        class="print-card"
        :style="{
          borderRadius: '8px',
          width: '500px',
          height: '666.08px',
          marginTop: '-200px',
          position: 'relative',
        }"
      >
        <v-card
          :style="{
            borderRadius: '10px',
            width: '450px',
            height: '600px',
            borderColor: color3,
            border: '3px solid',
            position: 'center',
            margin: '0 auto',
          }"
        >
          <v-sheet>
            <v-img
              :src="icon"
              :width="iconWidth"
              :style="{
                flex: '0 0 auto ',
                margin: '0 auto ',
              }"
              class="mx-auto print-visible"
            ></v-img>
            <div
              class="text-center mb-1 mt-6 justify-center pl-8 pr-8 print-text"
              style="margin-left: 12px; margin-right: 12px"
            >
              <p
                :style="{
                  styleTitle,
                  fontFamily: 'Affogato',
                  'font-size': '26px',
                  'line-height': '30px',
                  fontWeight: 700,
                  color: '#2D4490',
                }"
              >
                Scannez le QR code pour faire la photo d’identité officielle de
                votre permis de conduire
              </p>
            </div>
          </v-sheet>
          <br />
          <v-sheet
            class="
              d-flex
              align-center
              justify-center
              flex-wrap
              text-center
              mx-auto
              px-4
              rounded-xl
              print-sheet
            "
            :style="{
              top: '1677px',
              left: '790px',
              border: '3px solid',
              borderColor: '#37E3AE',
              background: '#FFFFFF',
              margin: '0 auto',
            }"
            :elevation="1"
            :height="200"
            :width="200"
          >
            <v-img :src="imageSrc"></v-img>
          </v-sheet>
          <br />
          <div
            style="
              display: flex;
              align-items: center;
              position: absolute;
              left: 150px;
              bottom: 10px;
            "
          >
            <p
              :style="{
                textAlign: 'center',
                margin: '0',
                color: '#000000',
                'font-family': 'Georama',
                fontWeight: 700,
                'font-size': '10px',
              }"
            >
              Made by Smartphone
            </p>
            <v-img
              :src="icon"
              :width="iconWidth"
              :style="{
                flex: '0 0 auto',
                maxWidth: '35.84px',
                maxHeight: '25.72px',
                margin: '0',
                borderRadius: '10px',
              }"
              class="mx-auto"
            ></v-img>
          </div>
        </v-card>
      </v-card>
      <v-sheet>
        <div
          style="
            position: absolute;
            bottom: 10px;
            left: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          "
          class="pl-3"
        >
          <p
            :style="{
              textAlign: 'center',
              margin: '0',
              color: '#000000',
              fontWeight: 700,
            }"
          >
            Made by Smartphone
          </p>
          <v-img
            :src="icon"
            :width="iconWidth"
            :style="{
              flex: '0 0 auto',
              maxWidth: '165.37px',
              maxHeight: '41.07px',
              margin: '5px 0 0 10px',
              borderRadius: '10px',
            }"
            class="mx-auto mb-1"
          ></v-img>
        </div>
      </v-sheet>
    </v-sheet>
  </v-container>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import { saveAs } from 'file-saver'
//import { requestService } from '@/services/request.service'
export default {
  name: 'Authorization',
  data() {
    return {
      showForm: true,
      email: '',
      confEmail: '',
      agenceName: '',
      externalId: '',
      isClicked: false,
      qrCode: null,
      imageSrc: null,
      link: null,
      company: process.env.VUE_APP_COMPANY_PRO_LEVEL_ONE,
      partnerPlan: process.env.VUE_APP_PARTNERPLAN_PRO_LEVEL_ONE,
      partnerAdmin: process.env.VUE_APP_PARTNERADMIN_PRO_LEVEL_ONE,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),

      color1: localStorageService.getStyle(location.hostname, 'color1'),
      color2: localStorageService.getStyle(location.hostname, 'color2'),
      color3: localStorageService.getStyle(location.hostname, 'color3'),
    }
  },
  methods: {
    goBack() {
      this.email = ''
      this.confEmail = ''
      this.agenceName = ''
      this.externalId = ''
      this.showForm = true
    },
    showFormAction() {
      this.showForm = true
    },
    checkForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.errors = []
      const isValidEmail = emailRegex.test(this.email)
      if (!this.email) {
        this.errors.push('Saisissez votre email')
      } else if (!isValidEmail) {
        this.errors.push("Cette adresses e-mail n'est pas valide")
      } else if (!this.confEmail || this.email !== this.confEmail) {
        this.errors.push('Confirmez votre email')
      } else if (!this.agenceName) {
        this.errors.push("Le Nom de l'auto-école est obligatoire")
      } else {
        return true
      }
      return false
    },
    submit() {
      if (!this.checkForm()) {
        return
      }

      requestService
        .post('/service/generate-qrcode/for-partner/' + this.partnerAdmin, {
          email: this.email,
          lastName: this.agenceName,
          firstName: '',
          language: 'fr',
          country: 'France',
          company: this.company,
          partnerPlan: this.partnerPlan,
          externalId: this.externalId,
        })
        .then(response => {
          this.link = response.data.link
          this.imageSrc = 'data:image/jpeg;base64,' + response.data.qrCode
          this.qrCode = response.data.qrCode
          this.showForm = false
        })
    },
    downloadFile() {
      const byteCharacters = atob(this.qrCode)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/octet-stream' })
      saveAs(blob, 'smartphone-id_qrCode.jpeg')
    },
  },

  mounted() {
    const uuid = this.$route.params.uuid
    if (uuid !== undefined || uuid !== null) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.company = response.data.company
          this.partnerPlan = response.data.partnerPlan
          this.partnerAdmin = response.data.partnerAdmin
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
  },
}
</script>
<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}

a {
  color: black;
}
.background-container {
  /*background-size: cover;*/
  height: 100vh !important; /* Prend toute la hauteur de l'écran */
  width: 100vw !important; /* Prend toute la largeur de l'écran */
  display: flex;
}

html,
body {
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden !important; /* Désactive le défilement vertical et horizontal sur le corps et l'html */
}

.v-card {
  border-radius: 0px 25px 25px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
