var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"background-container",style:({
    backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'stretch',
    overflowY: 'hidden',
    padding: '0px',
    backgroundSize: '57% 100%',
    backgroundPosition: 'right center',
  }),attrs:{"fluid":""}},[(_vm.showForm)?_c('v-sheet',{staticClass:"d-flex flex-row align-center justify-center",style:({
      borderRadius: '0px 25px 25px 0px',
      width: '45%',
      height: '100%',
    })},[_c('v-sheet',[_c('v-img',{staticClass:"mx-auto mb-10",attrs:{"src":_vm.icon,"width":_vm.iconWidth}}),_c('div',[_c('h1',{staticClass:"text-center mb-2",style:({
            styleTitle: _vm.styleTitle,
            fontFamily: 'Georama',
            fontSize: 24,
            fontWeight: 700,
            marginTop: '15px',
          })},[_vm._v(" Demande d’autorisation ")])]),_c('br'),_c('v-sheet',{staticClass:"d-flex flex-column justify-center"},[_c('v-text-field',{staticStyle:{"width":"400px","height":"52px"},style:({ 'margin-top': '20px', borderRadius: '6px' }),attrs:{"density":"dense","placeholder":"Nom Auto-ecole","prepend-inner-icon":"mdi-car-outline","solo":"","outlined":""},model:{value:(_vm.agenceName),callback:function ($$v) {_vm.agenceName=$$v},expression:"agenceName"}}),_c('v-text-field',{staticStyle:{"width":"400px","height":"52px"},style:({ 'margin-top': '20px', borderRadius: '6px' }),attrs:{"density":"dense","placeholder":"Email de l'agence","prepend-inner-icon":"mdi-email-outline","solo":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticStyle:{"width":"400px","height":"52px"},style:({ 'margin-top': '20px', borderRadius: '6px' }),attrs:{"density":"dense","placeholder":"Confirmer Email","prepend-inner-icon":"mdi-email-outline","solo":"","outlined":""},model:{value:(_vm.confEmail),callback:function ($$v) {_vm.confEmail=$$v},expression:"confEmail"}}),_c('v-text-field',{staticStyle:{"width":"400px","height":"62px"},style:({
            'margin-top': '20px',
            borderColor: '#47D881',
            borderRadius: '6px',
          }),attrs:{"density":"dense","placeholder":"Identifiant interne","prepend-inner-icon":"mdi-account-outline","solo":"","outlined":""},model:{value:(_vm.externalId),callback:function ($$v) {_vm.externalId=$$v},expression:"externalId"}}),_c('v-btn',{style:({
            color: _vm.buttonTextValidColor,
            borderRadius: '30px',
            'text-transform': 'none',
            padding: '25px 70px',
            'font-family': 'Georama',
            'font-size': '24px',
            'font-weight': '600',
            'line-height': '20px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '20px',
          }),attrs:{"color":_vm.color1},on:{"click":_vm.submit}},[_vm._v("Envoyer")])],1),_c('div',{staticClass:"pl-3",staticStyle:{"position":"absolute","bottom":"10px","left":"10px","display":"flex","justify-content":"flex-end","align-items":"flex-end"}},[_c('p',{style:({
            textAlign: 'center',
            margin: '0',
            color: '#000000',
            fontWeight: 700,
          })},[_vm._v(" Made by Smartphone ")]),_c('v-img',{staticClass:"mx-auto mb-1",style:({
            flex: '0 0 auto',
            maxWidth: '165.37px',
            maxHeight: '41.07px',
            margin: '5px 0 0 10px',
            borderRadius: '10px',
          }),attrs:{"src":_vm.icon,"width":_vm.iconWidth}})],1)],1)],1):_c('v-sheet',{staticClass:"d-flex flex-column align-center justify-center",style:({
      borderRadius: '0px 25px 25px 0px',
      width: '45%',
      height: '100%',
    })},[_c('v-btn',{staticClass:"text-none",style:({
        'font-family': 'Georama',
        'font-size': '16px',
        'font-weight': '400',
        'line-height': '20px',
        'letter-spacing': '0em',
        position: _vm.absolute,
        bottom: '-470px',
        left: '300px',
        display: _vm.flex,
        borderRadius: '50px',
        width: '60px',
        height: '60px',
        backgroundColor: '#FFFFFF',
      }),attrs:{"title":"Home"},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"center":"","size":"36"}},[_vm._v("mdi-home")])],1),_c('v-card',{staticClass:"print-card",style:({
        borderRadius: '8px',
        width: '500px',
        height: '666.08px',
        marginTop: '-200px',
        position: 'relative',
      })},[_c('v-card',{style:({
          borderRadius: '10px',
          width: '450px',
          height: '600px',
          borderColor: _vm.color3,
          border: '3px solid',
          position: 'center',
          margin: '0 auto',
        })},[_c('v-sheet',[_c('v-img',{staticClass:"mx-auto print-visible",style:({
              flex: '0 0 auto ',
              margin: '0 auto ',
            }),attrs:{"src":_vm.icon,"width":_vm.iconWidth}}),_c('div',{staticClass:"text-center mb-1 mt-6 justify-center pl-8 pr-8 print-text",staticStyle:{"margin-left":"12px","margin-right":"12px"}},[_c('p',{style:({
                styleTitle: _vm.styleTitle,
                fontFamily: 'Affogato',
                'font-size': '26px',
                'line-height': '30px',
                fontWeight: 700,
                color: '#2D4490',
              })},[_vm._v(" Scannez le QR code pour faire la photo d’identité officielle de votre permis de conduire ")])])],1),_c('br'),_c('v-sheet',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            flex-wrap\n            text-center\n            mx-auto\n            px-4\n            rounded-xl\n            print-sheet\n          ",style:({
            top: '1677px',
            left: '790px',
            border: '3px solid',
            borderColor: '#37E3AE',
            background: '#FFFFFF',
            margin: '0 auto',
          }),attrs:{"elevation":1,"height":200,"width":200}},[_c('v-img',{attrs:{"src":_vm.imageSrc}})],1),_c('br'),_c('div',{staticStyle:{"display":"flex","align-items":"center","position":"absolute","left":"150px","bottom":"10px"}},[_c('p',{style:({
              textAlign: 'center',
              margin: '0',
              color: '#000000',
              'font-family': 'Georama',
              fontWeight: 700,
              'font-size': '10px',
            })},[_vm._v(" Made by Smartphone ")]),_c('v-img',{staticClass:"mx-auto",style:({
              flex: '0 0 auto',
              maxWidth: '35.84px',
              maxHeight: '25.72px',
              margin: '0',
              borderRadius: '10px',
            }),attrs:{"src":_vm.icon,"width":_vm.iconWidth}})],1)],1)],1),_c('v-sheet',[_c('div',{staticClass:"pl-3",staticStyle:{"position":"absolute","bottom":"10px","left":"10px","display":"flex","justify-content":"flex-end","align-items":"flex-end"}},[_c('p',{style:({
            textAlign: 'center',
            margin: '0',
            color: '#000000',
            fontWeight: 700,
          })},[_vm._v(" Made by Smartphone ")]),_c('v-img',{staticClass:"mx-auto mb-1",style:({
            flex: '0 0 auto',
            maxWidth: '165.37px',
            maxHeight: '41.07px',
            margin: '5px 0 0 10px',
            borderRadius: '10px',
          }),attrs:{"src":_vm.icon,"width":_vm.iconWidth}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }