<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="mobile-only" style="margin: 10px">
      <!-- <v-btn
        class="mt-10 d-sm-none text-none"
        @click="redirectNewOrder"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn> -->
    </div>
    <div class="content-container">
      <h1
        :style="{
          ...styleTitle,
          marginTop: '20px',
          marginRight: '20px',
          fontSize: '1.5em',
        }"
      >
        {{ payByUser ? 3 : 2 }} étapes faciles
      </h1>
      <br />
      <div v-if="rejectStatus == undefined">
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="6" md="4">
            <v-card class="rounded-xl mt-1 mx-auto" style="padding: 10px">
              <div style="display: flex; align-items: center">
                <h1
                  style="margin-bottom: 2px; color: #000000; font-size: 1.8em"
                  class="pl-2"
                >
                  1
                </h1>
                <strong style="margin-left: 10px">La Photo</strong>
              </div>
              <div style="width: 100%; margin-top: 2px">
                <p
                  style="
                    font-family: 'Georama';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                  "
                  class="pl-4"
                >
                  Prenez votre photo en vous mettant face à la lumière, de
                  préférence sur un fond uni, retirez vos lunettes.
                </p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="rounded-xl mt-1 mx-auto" style="padding: 10px">
              <div style="display: flex; align-items: center">
                <h1
                  style="margin-bottom: 2px; color: #000000; font-size: 1.8em"
                  class="pl-2"
                >
                  2
                </h1>
                <strong style="margin-left: 10px">La Signature</strong>
              </div>
              <div style="width: 100%; margin-top: 2px">
                <p
                  style="
                    font-family: 'Georama';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #00000;
                  "
                  class="pl-4"
                >
                  Certifiez votre photo avec une signature de votre doigt à
                  l’intérieur du cadre.
                </p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="rounded-xl mt-1 mx-auto" style="padding: 10px">
              <div style="display: flex; align-items: center">
                <h1
                  style="margin-bottom: 2px; color: #OOOOOO; font-size: 1.8em"
                  class="pl-2"
                >
                  3
                </h1>
                <strong style="margin-left: 10px">Le Paiement</strong>
              </div>
              <div style="width: 100%; margin-top: 2px">
                <p
                  style="
                    font-family: 'Georama';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #00000;
                  "
                  class="pl-4"
                >
                  Réglez votre commande dans un environnement sécurisé et suivez
                  son traitement.
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <br />
      <v-btn
        :color="color4"
        class="centered-button mt-2"
        :disabled="canvasEmpty"
        @click="validate"
        :loading="uploading"
        :style="{
          color: buttonTextValidColor,
          borderRadius: '20px',
          'text-transform': 'none',
          padding: '25px 100px',
          'font-family': 'Georama',
          'font-size': '14px',
          'font-weight': '600',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '20px',
        }"
        >Continuer</v-btn
      >
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'Welcome',
  data() {
    return {
      rejectStatus: null,
      photoRejectReasons: null,
      payByUser: true,

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      timelineColor: localStorageService.getStyle(
        location.hostname,
        'timelineColor',
      ),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    }
  },
  methods: {
    validate() {
      this.$router.push({ name: 'ShootPhoto' })
      //this.$router.push({ name: 'TipsPhoto' })
    },
    redirectNewOrder() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
  },
  mounted() {
    if (localStorageService.getValue('shortliveApiKey') === undefined) {
      this.$router.push({ name: 'NotAuthorized' })
    } else {
      this.rejectStatus = localStorageService.getValue('rejectStatus')
      this.photoRejectReasons =
        localStorageService.getValue('photoRejectReasons')
      this.payByUser = localStorageService.getValue('payByUser')
    }
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}

.background-container {
  display: flex;
  flex-direction: column;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.v-card {
  width: 327px;
  height: 129px;
}
.mobile-only {
  display: none;
}
@media screen and (max-width: 600px) {
  .mobile-only {
    display: block;
  }
  .centered-button {
    margin-left: 0;
  }
}
</style>
